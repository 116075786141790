import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchIssuesStore = defineStore('searchIssuesStore', {
  state: () => ({
    issues_type: null as string | null,
    issues_status: null as string | null,
    issues_subject: null as string | null,
    issues_sender: null as string | null,
    issues_receiver: null as string | null,
    issues_sender_id: null as number | null,
    issues_receiver_id: null as number | null,
    issues_assigned_person: null as number | null,
    items: [
      { key: 'issues_type', operation: ':' },
      { key: 'issues_status', operation: ':' },
      { key: 'issues_subject', operation: ':' },
      { key: 'issues_sender', operation: ':' },
      { key: 'issues_receiver', operation: ':' },
      { key: 'issues_sender_id', operation: ':' },
      { key: 'issues_receiver_id', operation: ':' },
      { key: 'issues_assigned_person', operation: ':' }
    ] as TermItemDefinition[]
  })
})
