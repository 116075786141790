<template>
  <div>
    <h2 v-html="$t('search_issues_title')"></h2>
    <!-- Typ -->
    <v-select
      id="search_issues_type"
      v-model="searchIssuesStore.issues_type"
      :label="$t('search_issues_type')"
      :items="issueTypes"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Status -->
    <v-select
      id="search_issues_status"
      v-model="searchIssuesStore.issues_status"
      :label="$t('search_issues_status')"
      :items="issueStatuses"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Betreff -->
    <v-text-field
      id="search_issues_regarding"
      v-model="searchIssuesStore.issues_subject"
      :label="$t('search_issues_regarding')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Absender | Legal Entity Picker-->
    <dbm-extended-legal-entity-picker
      v-model="searchIssuesStore.issues_sender_id"
      :label="$t('search_issues_sender')"
      name="search_issues_sender"
      :cards="['searchApplicationUser', 'searchFarm', 'searchLegalEntity']"
    />
    <!-- Empfänger | Legal Entity Picker -->
    <dbm-extended-legal-entity-picker
      v-model="searchIssuesStore.issues_receiver_id"
      :label="$t('issue_edit_receiver')"
      name="issue_edit_receiver"
      :cards="['searchApplicationUser', 'searchFarm', 'searchLegalEntity']"
    />
    <!-- Zugewiesene Person -->
    <v-select
      :disabled="!searchIssuesStore.issues_receiver_id"
      id="search_issues_assigned_person"
      v-model="searchIssuesStore.issues_assigned_person"
      :label="$t('search_issues_assigned_person')"
      :items="responsibleUsers"
      item-title="name"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchIssuesStore } from '@/store/SearchIssuesStore'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import { defineComponent, watchEffect } from 'vue'
import DbmExtendedLegalEntityPicker from '@/components/pickers/dbmExtendedLegalEntityPicker.vue'
import { useIssueStatusesStore } from '@/store/enums/IssueStatusesStore'
import { useIssueTypesStore } from '@/store/enums/IssueTypesStore'

export default defineComponent({
  name: 'searchIssues',
  extends: baseSearchVue,
  data() {
    return {
      loading: false,
      totalElements: 0,
      dialogsettings: { loading: false, totalElements: 0 },
      selected: [],
      items: [],
      dialog: false,
      sender: false,
      receiver: false,
      responsibleUsers: []
    }
  },
  computed: {
    issueTypes() {
      return useIssueTypesStore().issueTypes
    },
    issueStatuses() {
      return useIssueStatusesStore().issueStatuses
    },
    searchIssuesStore() {
      return useSearchIssuesStore()
    },
    query(): any {
      return {
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    DbmExtendedLegalEntityPicker
  },
  methods: {
    reset() {
      this.searchIssuesStore.issues_receiver = null
      this.searchIssuesStore.issues_receiver_id = null
      this.searchIssuesStore.issues_sender_id = null
      this.searchIssuesStore.issues_sender = null
      this.searchIssuesStore.issues_type = null
      this.searchIssuesStore.issues_status = null
      this.searchIssuesStore.issues_assigned_person = null
      this.searchIssuesStore.issues_subject = null
      this.resetFields(this.searchIssuesStore)
    },
    async loadResponsibleUsers() {
      try {
        const response = await this.axios.get(apiURL + '/applicationUsers/findByLegalEntityId', {
          params: {
            legalEntityId: this.searchIssuesStore.issues_receiver_id
          }
        })
        if (await response) {
          const responsibleUsers = response.data
          this.responsibleUsers = Array.isArray(responsibleUsers) ? responsibleUsers : []
        }
      } catch (e) {
        this.items = []
        showError(e)
      } finally {
        this.items = []
      }
    }
  },
  beforeMount() {
    useIssueStatusesStore().fetchIssueStatuses()
    useIssueTypesStore().fetchIssueTypes()
  },
  mounted() {
    watchEffect(() => {
      const receiverId = this.searchIssuesStore.issues_receiver_id
      if (receiverId) {
        this.loadResponsibleUsers()
      } else {
        this.responsibleUsers = []
      }
    })
    this.initSearchStoreFromQuery(this.searchIssuesStore, this.$route.query)
  }
})
</script>
